/* Login page css fixed */
.Pages-fullPage-2 {
    align-items: unset !important;
    padding: 90px 0 !important;
}

.Pages-fullPage-2:before {
    background-color: rgba(0,0,0,0) !important;
}

.recaptchaContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.forgetPassword {
    text-align: end;
    margin-bottom: 30px;
}

.switchLanguageButton {
    padding: 10px;
    font-size: 18px;
    color: #1457c4;
}

@media(min-width: 599px){
    .switchLanguageButton {
        color: #86BACF;
    } 
}

.fixedBTColorLogin {
    background-color: #43425D !important;
}

.fixedBTColorReg {
    color: #43425D !important;
}

.mobileLanguageSwitchBT {
    padding: 10px;
    font-size: 18px;
}

.loadingBt {
    margin-right: 20px;
}

.fixedDropdown {
    margin: 0 0 17px 0 !important;
    position: relative !important;
    padding-top: 27px !important;
    vertical-align: unset !important;
}
/* ================ */
/* end of Login css */

/* Modal component css fixed */
.modalBt {
    color: #FFF;
    border: none;
    cursor: pointer;
    margin: .3125rem 1px;
    padding: 12px 30px;
    position: relative;
    font-size: 12px;
    min-width: auto;
    min-height: auto;
    text-align: center;
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    font-weight: 400;
    line-height: 1.42857143;
    white-space: nowrap;
    will-change: box-shadow, transform;
    touch-action: manipulation;
    border-radius: 3px;
    letter-spacing: 0;
    text-transform: uppercase;
    vertical-align: middle;
    box-shadow: 0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12);
    background-color: #43425D;
}

.modalBt:hover {
    background-color: #63636A;
}

.modalBtCancel {
    background-color: transparent !important;
    color: #43425D !important;
    transition: none !important;
    box-shadow: none !important;
}

.modalBtCancel:hover {
    color: #63636A !important;
}

/* .modalContent {
    top: 40% !important;
} */

.modalContent > h2 {
    font-size: 28px !important;
    font-weight: 500 !important;
}

.GridItem-grid-204 {
    text-align: center;
}

/* Sidebar component css fixed */
.sider_logo_img {
    width: 85%;
}

.fixOveflow {
    overflow-x: hidden;
    padding: 5px;
}

/* @media (max-width: 959.95px) {
    .HeaderLinks-popperNav-223 > div ul li{
        color: #000 !important;
    }
} */

.style_navpills {
    border-radius: 0px !important;
}

/* @media(min-width: 960){
    .project_donut {
        background-color: #1457c4;
        height: 200px;
    }
    
    .project_map {
        background-color: #1457c4;
    }
}

.project_donut {
    background-color: #FFF;
    height: auto;
}

.project_map {
    background-color: #FFF;
    height: auto;
} */

.change_tabColor {
    background: linear-gradient(60deg, #384D97, #384D97) !important;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(0, 172, 193,.4);
}

.card_imageBody {
    padding: 0 !important;
}

.card_imagePic {
    width: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.card_imageFooter {
    margin-top: 0 !important;
    flex-direction: column;
    align-items: flex-start !important;
}

.card_loading {
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.15);
    position: absolute;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}

.form_radio {
    justify-content: flex-start;
    align-items: baseline;
    padding-left: 15px;
}

.form_radio_control {
    justify-content: flex-start;
    align-items: baseline;
}

.resize_imageupload {
    max-width: 2700px !important;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0,.12), 0 7px 10px -5px rgba(0, 0, 0,.12) !important;
}

.rdt input.form-control {
    font-size: 14px;
    padding-top: 10px;
}